body {
  margin: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%; /* Position the dropdown content below the parent */
  left: 0;
  z-index: 1;
  list-style: none;
  font-weight: 600;
  background-color: white;
  padding-left: 20px;
  border-radius: 20px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.slider-image {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transform: scale(0.95);
}

.slider-image.opacity-100 {
  opacity: 1;
  transform: scale(1);
}

.slider-image.opacity-0 {
  opacity: 0;
  transform: scale(0.95);
}

.slider-image.visible {
  visibility: visible;
}

.slider-image.invisible {
  visibility: hidden;
}

/* Adjust for iPhone 12 Pro Max and similar Samsung devices */
@media (max-width: 428px) {
  .mobile-margin-adjust {
    margin-top: -85px; /* Adjust this value as needed */
  }
}

/* Adjust for tablets and other larger mobile devices */
@media (max-width: 768px) {
  .mobile-margin-adjust {
    margin-top: -85px; /* Adjust this value as needed */
  }
}
